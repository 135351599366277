import {ReactNode} from 'react';
import clsx from 'clsx';
import {CancelIcon} from '../icons';
import ButtonIcon from '../button/buttonIcon';
import * as styles from './bulkAction.module.css';

interface IBulkActionProps {
    selectedRowsNumber: number,
    buttons: ReactNode,
    onClose: () => void,
    itemsName: string,
    rootClass?: keyof typeof styles,
}

const BulkAction = ({
    selectedRowsNumber = 0,
    buttons,
    onClose,
    itemsName = 'items',
    rootClass,
}: IBulkActionProps) => (
    <div className={clsx(styles.root, rootClass && styles[rootClass])}>
        <div className={styles.bulkaction}>
            <ButtonIcon
                icon={CancelIcon}
                tooltipText={`Unselect all ${itemsName}`}
                onClick={onClose}
                isClose={true}
            />
            <div className={styles.text}>
                <p>{selectedRowsNumber} {itemsName} selected</p>
            </div>
            {buttons}
        </div>
    </div>
);

export default BulkAction;
