import {ReactNode} from 'react';
import * as styles from './buttonGroup.module.css';

const ButtonGroup = ({children}: {children: ReactNode}) => (
    <div className={styles.buttonGroup}>
        {children}
    </div>
);

export default ButtonGroup;
