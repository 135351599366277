import clsx from 'clsx';
import React, {MouseEventHandler} from 'react';
import Tooltip from '../tooltip/tooltip';
import * as styles from './buttonIcon.module.css';

interface IButtonIconProps {
    tooltipText: string,
    icon: React.JSXElementConstructor<React.SVGProps<SVGSVGElement>>,
    onClick?: (e: React.MouseEvent<Element>) => void,
    isWhite?: boolean,
    isClose?: boolean,
    disabled?: boolean,
    active?: boolean,
    isButton?: boolean,
    href?: string,
    target?: string,
    sidePadding?: number,
    color?: string,
}

const ButtonIcon = ({
    tooltipText,
    icon: Icon,
    onClick = () => {},
    isWhite = false,
    isClose = false,
    disabled = false,
    isButton = true,
    sidePadding = 1,
    active,
    href,
    target = '_self',
    color,
}: IButtonIconProps) => {
    const handleButtonClick: MouseEventHandler = e => {
        if (disabled) {
            return;
        }

        onClick(e);
    };

    const link = disabled ? '#' : href;

    const LinkIcon = () => (
        <>
            {disabled && (
                <span
                    className={clsx(
                        styles.button,
                        styles.disabled,
                    )}
                    style={{
                        '--sidePadding': sidePadding,
                    } as React.CSSProperties}
                    onClick={handleButtonClick}
                >
                    {Icon && (<Icon/>)}
                </span>
            )}
            {!disabled && (
                <a
                    className={styles.button}
                    style={{
                        '--sidePadding': sidePadding,
                    } as React.CSSProperties}
                    href={link}
                    target={target}
                >
                    {Icon && (<Icon/>)}
                </a>
            )}
        </>
    );

    return (
        <>
            {isButton && (
                <Tooltip text={tooltipText}>
                    <button
                        className={clsx(
                            styles.button,
                            isWhite && styles.white,
                            isClose && styles.close,
                            active && styles.active,
                        )}
                        style={{
                            '--sidePadding': sidePadding,
                            color,
                        } as React.CSSProperties}
                        onClick={handleButtonClick}
                        disabled={disabled}
                    >
                        {Icon && (
                            <Icon/>
                        )}
                    </button>
                </Tooltip>
            )}
            {!isButton && (
                <Tooltip text={tooltipText}>
                    <LinkIcon/>
                </Tooltip>
            )}
        </>

    );
};

export default ButtonIcon;
