import {useCallback, useEffect, useState} from 'react';
import clsx from 'clsx';
import {useLazyQuery, useMutation, useReactiveVar} from '@apollo/client';
import moment from 'moment/moment';
import {navigate} from 'gatsby';
import {Layout} from '../../components/layout';
import {
    Button, Checkbox, Tabs, Tooltip,
} from '../../components/core';
import {getTabsData} from '../../configs/pages/home/tabs';
import * as styles from './index.module.css';
import BulkAction from '../../components/core/bulkaction/bulkAction';
import {NavArrowRightIcon, RefreshIcon, YoutubeIcon} from '../../components/core/icons';
import ButtonGroup from '../../components/core/button/buttonGroup';
import {GetVideoYoutubeDiffsDocument, ScheduleVideoYoutubeCheckDocument} from '../../graphql-types';
import toastify from '../../helpers/toast/toastify';
import {youtubeIdURLPrefix} from '../../configs/pages/videos/youtube';
import {accountVar} from '../../helpers/graphql/variables';
import {UserApplicationScopes} from '../../interfaces/applications';

interface ICheckAbstract {
    uploadId: string,
    videoId: string,
    mcbt: string,
    name: string,
    processedAt: string,
    channelId: string,
    youtubeId: string,
}

interface ICheckItem extends ICheckAbstract {
    mutationId: string,
    fields: {
        field: string,
        message?: string,
    }[],
}

interface ICheckDiffData extends ICheckAbstract {
    field: string,
    message?: string,
}

const ContentIntegrityCheck = () => {
    const account = useReactiveVar(accountVar);
    const accountPermissions: Partial<UserApplicationScopes> = account ? account?.permissions || {} : {};
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [selectedRowsNumber, setSelectedRowsNumber] = useState(0);
    const [checkItems, setCheckItems] = useState<ICheckItem[]>([]);

    const [youtubeCheckStatus, {data, error}] = useLazyQuery(GetVideoYoutubeDiffsDocument);
    const [scheduleCheck] = useMutation(ScheduleVideoYoutubeCheckDocument);

    useEffect(() => {
        youtubeCheckStatus();
    }, [youtubeCheckStatus]);

    const toggleRowChecked = (rowId?: string | number) => setSelectedIds(prevState => {
        if (!rowId || typeof rowId !== 'string') {
            return prevState;
        }

        if (prevState.includes(rowId)) {
            return prevState.filter(item => item !== rowId);
        }

        return [...prevState, rowId];
    });

    const isRowChecked = useCallback((rowId: string) => selectedIds.includes(rowId), [selectedIds]);

    const handleRefreshClick = (uploadIds: string[]) => {
        scheduleCheck({
            variables: {uploadIds},
            onCompleted() {
                setSelectedIds([]);
                toastify({
                    type: 'success',
                    text: 'Integrity check update scheduled for next run',
                });
            },
            onError(err) {
                toastify({
                    type: 'error',
                    text: `An error occurred: ${err.message}`,
                });
            },
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
    };

    useEffect(() => {
        setSelectedRowsNumber(selectedIds.length);
    }, [selectedIds]);

    useEffect(() => {
        if (!data) {
            return;
        }

        const newCheckItemsPrep: Record<string, ICheckItem> = {};

        const addDiffToCheckItem = (mutationId: string, diffData: ICheckDiffData) => {
            if (!mutationId) {
                return;
            }

            if (newCheckItemsPrep[mutationId] === undefined) {
                newCheckItemsPrep[mutationId] = {
                    uploadId: diffData.uploadId,
                    videoId: diffData.videoId,
                    mcbt: diffData.mcbt,
                    name: diffData.name,
                    processedAt: diffData.processedAt,
                    channelId: diffData.channelId,
                    youtubeId: diffData.youtubeId,
                    mutationId,
                    fields: [],
                };
            }

            newCheckItemsPrep[mutationId].fields.push({field: diffData.field, message: diffData.message});
        };

        data.videoYoutubeDiffs.forEach(item => {
            const itemMutations = item.upload.mutations;

            item.diffs.forEach(diff => {
                if (diff.__typename === 'MutationYoutubeDiff') {
                    const diffData = {
                        uploadId: item.upload.id,
                        videoId: diff.mutation?.video.id,
                        mcbt: diff.mutation?.video.mcbt as string,
                        name: diff.mutation?.name as string,
                        processedAt: item.processedAt,
                        channelId: diff.mutation?.video.channel.id,
                        youtubeId: item.upload.videoId,
                        field: diff.field,
                        message: diff.message || undefined,
                    };

                    addDiffToCheckItem(diff.mutation?.id, diffData);

                    return;
                }

                itemMutations.forEach(mutation => {
                    const diffData = {
                        uploadId: item.upload.id,
                        videoId: mutation.video.id,
                        mcbt: mutation.video.mcbt,
                        name: mutation.name,
                        processedAt: item.processedAt,
                        channelId: mutation.video.channel.id,
                        youtubeId: item.upload.videoId,
                        field: diff.field,
                        message: diff.message || undefined,
                    };

                    addDiffToCheckItem(mutation.id, diffData);
                });
            });
        });

        const newCheckItems: Array<ICheckItem> = Object.keys(newCheckItemsPrep).map(item => newCheckItemsPrep[item]);

        setCheckItems(newCheckItems);
    }, [data]);

    return (
        <Layout title="Home center" scope="videoYoutubeCheck.read">
            <Tabs
                data={getTabsData(accountPermissions)}
            />
            <div className={styles.root}>
                <div className={styles.wrapper}>
                    {accountPermissions.videoYoutubeCheck?.write && selectedRowsNumber > 0 && (
                        <BulkAction
                            selectedRowsNumber={selectedRowsNumber}
                            itemsName="videos"
                            buttons={(
                                <ButtonGroup>
                                    <Button
                                        text="Refresh"
                                        icon={RefreshIcon}
                                        onClick={() => handleRefreshClick(selectedIds)}
                                        size="tiny"
                                        color="white"
                                    />
                                </ButtonGroup>
                            )}
                            onClose={() => setSelectedIds([])}
                            rootClass="closer"
                        />
                    )}
                    {checkItems.map(item => (
                        <div key={item.mutationId} className={styles.checkItem}>
                            {accountPermissions.videoYoutubeCheck?.write && (
                                <div>
                                    <Checkbox
                                        value={item.uploadId}
                                        checked={isRowChecked(item.uploadId)}
                                        handleChange={toggleRowChecked}
                                        className={styles.checkbox}
                                    />
                                </div>
                            )}
                            <div className={styles.info}>
                                <div>
                                    <span className={styles.mcbt}>{item.mcbt}</span>
                                    <span>/{item.name}</span>
                                </div>
                                <div>{moment(item.processedAt).format('MMM D, YYYY')}</div>
                            </div>
                            <div className={styles.results}>
                                {item.fields.map(diff => (
                                    <div key={`${item.uploadId}-${diff.field}`}>
                                        {diff.message ? `${diff.message} (${diff.field})` : `difference in ${diff.field}`}
                                    </div>
                                ))}
                            </div>
                            <div className={styles.actions}>
                                <div className={styles.buttons}>
                                    {accountPermissions.videoYoutubeCheck?.write && (
                                        <Tooltip text="Refresh item">
                                            <button className={styles.button} onClick={() => handleRefreshClick([item.uploadId])}><RefreshIcon/></button>
                                        </Tooltip>
                                    )}
                                    <Tooltip text={item.youtubeId === '' ? 'Missing Youtube ID' : 'See on Youtube'}>
                                        <button className={clsx(styles.button, item.youtubeId === '' && styles.inactive)}>
                                            {item.youtubeId.length > 0 && (
                                                <a
                                                    href={`${youtubeIdURLPrefix}${item.youtubeId}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <YoutubeIcon/>
                                                </a>
                                            )}
                                            {item.youtubeId === '' && (
                                                <YoutubeIcon/>
                                            )}
                                        </button>
                                    </Tooltip>
                                    <Tooltip text="Go to mutation">
                                        <button
                                            className={clsx(styles.button, styles.blue)}
                                            onClick={() => navigate(
                                                `/videos/${item.channelId}/${item.videoId}/mutations`,
                                                {
                                                    state: {
                                                        activeMutationId: item.mutationId,
                                                    },
                                                },
                                            )}
                                        >
                                            <NavArrowRightIcon/>
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    ))}
                    {data && checkItems.length === 0 && 'No integrity violations registered'}
                    {error && 'Error loading integrity data'}
                </div>
            </div>
        </Layout>
    );
};

export default ContentIntegrityCheck;
